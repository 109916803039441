<template>
  <div>
    <v-card elevation="0" class="px-5">
      <v-row>
        <v-col md="3">
            <v-text-field :dark="$store.state.isDarkMode" :label="$t('hr.time.from-date')" type="date" v-model="filterData.from">
            </v-text-field>
        </v-col>
        <v-col md="3">
            <v-text-field :dark="$store.state.isDarkMode" :label="$t('hr.time.to-date')" type="date" v-model="filterData.to">
            </v-text-field>
        </v-col>
        <v-col md="2">
          <v-text-field :dark="$store.state.isDarkMode" :label="$t('number')" v-model="filterData.id" type="number"></v-text-field>
        </v-col>
        <v-col md="2">
          <v-text-field :dark="$store.state.isDarkMode" :label="$t('value')" v-model="filterData.amount" type="number"></v-text-field>
        </v-col>
        <v-col md="2">
          <v-autocomplete :dark="$store.state.isDarkMode" color="#757575" :label="$t('command type')" :items="orderTypes" item-value="value"
            item-text="value" v-model="filterData.action"></v-autocomplete>
        </v-col>
        <v-col md="2">
          <v-autocomplete :dark="$store.state.isDarkMode" :label="$t('account name')" :items="accountItems" :loading="accountItemsLoading"
            color="#757575" item-text="name" item-value="id" v-model="filterData.account" no-filter
            @keypress="fetchAutoCompleteAccount">
          </v-autocomplete>
        </v-col>
        <v-col md="2">
          <v-text-field :dark="$store.state.isDarkMode" :label="$t('explain')" v-model="filterData.desc"></v-text-field>
        </v-col>
        <v-col md="2">
          <v-autocomplete :dark="$store.state.isDarkMode" :label="$t('cost center')" :items="costCenterItems" color="#757575" item-text="name"
            item-value="id" :loading="costCenterItemsLoading" v-model="filterData.costCenter"
            @keypress="fetchAutoCompleteCostCenter" no-filter>
          </v-autocomplete>
        </v-col>
        <v-col md="2">
          <v-autocomplete :dark="$store.state.isDarkMode" :label="$t('state')" :items="stateItems" color="#757575" item-text="name" item-value="value"
            v-model="filterData.done" no-filter>
          </v-autocomplete>
        </v-col>
        <v-col md="2" class="d-flex justify-space-between align-center">
          <v-icon :dark="$store.state.isDarkMode" @click="filterTable" size="30">mdi-magnify</v-icon>
          <v-icon :dark="$store.state.isDarkMode" size="30" @click="resetFilter">mdi-close</v-icon>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<script>
import axios from "axios";
import debounce from "../../../../helpers/debounce";
import Notifications from "../../../../helpers/toast-notifications";
import i18n from "@/i18n";
export default {
  data() {
    return {
      orderTypes: [
        {
          name: this.$t('deposit'),
          value: "deposit",
        },
        {
          name: this.$t('pay'),
          value: "pay",
        },
      ],
      fromDialog: false,
      toDialog: false,
      filterData: {
        from: "",
        to: "",
        id: null,
        amount: null,
        action: null,
        account: null,
        desc: "",
        costCenter: null,
        done: null,
        page: 1,
      },
      menu: false,
      accountItems: [],
      accountItemsLoading: false,
      costCenterItems: [],
      costCenterItemsLoading: false,
      validDate: false,
    };
  },

  methods: {
    checkValidation() {
      if (this.filterData.from && this.filterData.to) {
        console.log("from", this.filterData.from);
        console.log("to", this.filterData.to);
        console.log(
          "if???",
          new Date(this.filterData.from) > new Date(this.filterData.to)
        );
        if (new Date(this.filterData.from) > new Date(this.filterData.to)) {
          return false;
        } else {
          console.log("1");
          return true;
        }
      } else {
        return true;
      }
    },
    resetFilter() {
      this.filterData.from = "";
      this.filterData.to = "";
      this.filterData.id = null;
      this.filterData.amount = null;
      this.filterData.action = "";
      this.filterData.account = null;
      this.filterData.desc = "";
      this.filterData.done = null;
      this.filterData.costCenter = null;
      this.filterData.page = 1;
      this.$emit("resetFilter");
    },
    filterTable() {
      if (this.checkValidation()) {
        this.$emit("filterTable", this.filterData);
      } else {
        Notifications(
          this.$t("date error"),
          { timeout: 4000, rtl: true },
          "info"
        );
      }
      console.log("data", this.filterData);
    },
    async fetchAutoCompleteAccount(searchQuery) {
      debounce(async () => {
        let searchValue = searchQuery.target.value;
        this.accountItemsLoading = true;
        if (!searchValue) return;
        try {
          const searchResult = await axios.get(
            "/accounting/account/auto-complete",
            {
              params: {
                query: searchValue,
              },
            }
          );
          this.accountItems = searchResult.data.data;
        } catch (err) {
          console.log("err", err);
        } finally {
          this.accountItemsLoading = false;
        }
      }, 500)();
    },
    async fetchAutoCompleteCostCenter(searchQuery) {
      debounce(async () => {
        let searchValue = searchQuery.target.value;
        this.costCenterItemsLoading = true;
        if (!searchValue) return;
        try {
          const searchResult = await axios.get(
            "/accounting/cost-center/auto-complete",
            {
              params: {
                query: searchValue,
              },
            }
          );
          this.costCenterItems = searchResult.data.data;
        } catch (err) {
          console.log("err", err);
        } finally {
          this.costCenterItemsLoading = false;
        }
      }, 500)();
    },
  },
  computed: {
    stateItems() {
      return [
        {
          name: this.$t("accepted"),
          value: "1",
        },
        {
          name: this.$t("rejected"),
          value: "'0'",
        },
      ];
    },
  },
};
</script>

<style></style>