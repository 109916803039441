<template>
  <div>
    <v-dialog v-model="dialog" width="700">
      <template v-slot:activator="{ on, attrs }">
        <v-btn v-if="orderType == 'deposit'" v-on="on" v-bind="attrs" rounded depressed color="green"
          class="white--text ml-2">
          <v-icon>mdi-plus</v-icon>
          {{ $t("Add a receipt") }}
        </v-btn>
        <v-btn v-if="orderType == 'pay'" v-on="on" v-bind="attrs" rounded depressed color="green"
          class="white--text ml-2">
          <v-icon>mdi-plus</v-icon>
          {{ $t("Add a payment order") }}
        </v-btn>
      </template>
      <v-card>
        <v-card-title class="d-flex justify-center card-title__heading">
          <h2 v-if="orderType == 'pay'">{{ $t("Add a payment order") }}</h2>
          <h2 v-if="orderType == 'deposit'">{{ $t("Add a receipt") }}</h2>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col md="4">
              <v-autocomplete :dark="$store.state.isDarkMode" :label="$t('boxes')" :items="boxItems" color="#757575" item-text="name" item-value="id"
                :loading="boxesLoading" v-model="financialOrderData.box_id" @keypress="fetchAutoCompleteBoxes($event)"
                no-filter>
              </v-autocomplete>
            </v-col>
            <v-col md="4">
              <v-text-field :dark="$store.state.isDarkMode" :label="$t('user name')" readonly v-model="username"></v-text-field>
            </v-col>
            <v-col md="4">
                <v-text-field :dark="$store.state.isDarkMode" :label="$t('date')" type="date" v-model="financialOrderData.date">
                </v-text-field>
            </v-col>
            <v-col md="4">
              <v-text-field :dark="$store.state.isDarkMode" type="number" color="#757575" :label="$t('value')"
                v-model="financialOrderData.amount"></v-text-field>
            </v-col>
            <v-col md="4">
              <v-autocomplete :dark="$store.state.isDarkMode" :label="$t('account name')" :items="accountItems" :loading="accountLoading"
                color="#757575" item-text="name" item-value="id" v-model="financialOrderData.account_id" no-filter
                @keypress="fetchAutoCompleteAccount($event)">
              </v-autocomplete>
            </v-col>
            <v-col md="4">
              <v-autocomplete :dark="$store.state.isDarkMode" :label="$t('cost center')" :items="costCenterItems" color="#757575" item-text="name"
                item-value="id" :loading="costCenterLoading" v-model="financialOrderData.cost_center_id"
                @keypress="fetchAutoCompleteCostCenter($event)" no-filter>
              </v-autocomplete>
            </v-col>
            <v-col md="8">
              <v-text-field :dark="$store.state.isDarkMode" :label="$t('explain')" v-model="financialOrderData.desc" color="#757575"></v-text-field>
            </v-col>
            <v-col md="4" class="d-flex justify-end align-center">
              <v-btn :disabled="disableBtn" class="white--text" color="green" :loading="submitBtnLoading"
                @click="submitOrder" v-if="orderType == 'pay'">{{ $t("create a payment order") }}
              </v-btn>
              <v-btn :disabled="disableBtn" class="white--text" color="green" @click="submitOrder"
                :loading="submitBtnLoading" v-if="orderType == 'deposit'">
                {{ $t("create a Receipt Order") }}
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="showIdDialog" width="600" persistent>
      <v-card>
        <v-card-title class="d-flex justify-center align-center">
          <h1>رقم الأمر :{{ idNumber }}</h1>
        </v-card-title>
        <v-card-actions class="d-flex justify-center align-center">
          <v-btn color="green" class="white--text" @click="fetchData">موافق</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import debounce from "../../../../helpers/debounce";
import axios from "axios";
export default {
  data() {
    return {
      costCenterItems: [],
      costCenterLoading: false,
      accountItems: [],
      accountLoading: false,
      dateDialog: false,
      boxItems: [],
      boxesLoading: false,
      dialog: false,
      financialOrderData: {
        box_id: null,
        date: new Date().toISOString().slice(0, 10),
        amount: null,
        account_id: null,
        desc: "",
        action: this.orderType,
        cost_center_id: null,
      },
      submitBtnLoading: false,
      showIdDialog: false,
      idNumber: null,
    };
  },
  computed: {
    username() {
      return this.$store.getters.getUserName;
    },
    disableBtn() {
      if (
        !this.financialOrderData.box_id ||
        !this.financialOrderData.date ||
        !this.financialOrderData.amount ||
        !this.financialOrderData.account_id ||
        !this.financialOrderData.desc
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
  props: ["orderType"],
  methods: {
    fetchData() {
      this.showIdDialog = false;
      this.$emit("reFetchDataAgain");
    },
    async submitOrder() {
      try {
        let requestBody = {
          box_id: this.financialOrderData.box_id,
          date: this.financialOrderData.date,
          amount: this.financialOrderData.amount,
          account_id: this.financialOrderData.account_id,
          desc: this.financialOrderData.desc,
          action: this.financialOrderData.action,
          cost_center_id: this.financialOrderData.cost_center_id,
        };
        if (!this.financialOrderData.cost_center_id) {
          delete requestBody.cost_center_id;
        }
        if (!this.financialOrderData.desc) {
          delete requestBody.desc;
        }
        this.submitBtnLoading = true;
        const response = await axios.post(
          "/accounting/financial-order",
          requestBody
        );
        this.dialog = false;
        this.idNumber = response.data.data.id;
        this.showIdDialog = true;
        console.log("order res", response);
      } catch (err) {
      } finally {
        this.submitBtnLoading = false;
      }
    },
    async fetchAutoCompleteAccount(searchQuery) {
      debounce(async () => {
        let searchValue = searchQuery.target.value;
        this.accountLoading = true;
        if (!searchValue) return;
        try {
          const searchResult = await axios.get(
            "/accounting/account/auto-complete",
            {
              params: {
                query: searchValue,
              },
            }
          );
          this.accountItems = searchResult.data.data;
        } catch (err) {
          console.log("err", err);
        } finally {
          this.accountLoading = false;
        }
      }, 500)();
    },
    async fetchAutoCompleteBoxes(searchQuery) {
      debounce(async () => {
        let searchValue = searchQuery.target.value;
        this.boxesLoading = true;
        if (!searchValue) return;
        try {
          const searchResult = await axios.get(
            "/accounting/account/auto-complete",
            {
              params: {
                query: searchValue,
              },
            }
          );
          this.boxItems = searchResult.data.data;
        } catch (err) {
          console.log("err", err);
        } finally {
          this.boxesLoading = false;
        }
      }, 500)();
    },

    async fetchAutoCompleteCostCenter(searchQuery) {
      debounce(async () => {
        let searchValue = searchQuery.target.value;
        this.costCenterLoading = true;
        if (!searchValue) return;
        try {
          const searchResult = await axios.get(
            "/accounting/cost-center/auto-complete",
            {
              params: {
                query: searchValue,
              },
            }
          );
          this.costCenterItems = searchResult.data.data;
        } catch (err) {
          console.log("err", err);
        } finally {
          this.costCenterLoading = false;
        }
      }, 500)();
    },
  },
};
</script>

<style></style>
